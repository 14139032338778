/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef, useCallback } from 'react';
// mui components
import Badge from '@mui/material/Badge';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  // collection,
  doc,
  onSnapshot,
  // query,
  // where
} from 'firebase/firestore';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { Button, Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import SimpleBarReact from 'simplebar-react';
import ToggleSwitch from './ToggleSwitch';
import { colorsArray, linkRegex } from '../../constants/constants';

// assets
import { ReactComponent as ActivityIcon } from '../../images/icon/activity_icon.svg';
import { ReactComponent as NotificationImg } from '../../images/icon/bell.svg';
import { ReactComponent as NoActivityIcon } from '../../images/icon/No-Activity.svg';
import { ReactComponent as NoAnnouncementIcon } from '../../images/icon/No-Announcement.svg';
import { ReactComponent as ArrowUp } from '../../images/icon/Arrow_up.svg';
import { ReactComponent as ArrowDown } from '../../images/icon/Arrow_down.svg';

import { useStyles } from '../Templates/CommonStyle';
import { fireBaseDB } from '../../firebase/firebase';
import useAccountInfo from '../../hooks/useAccountInfo';
import useNotificationList from '../../hooks/useNotificationList';
import { NotificationDetails } from '../../types/apis/notificationGetApi';
import Loader from './Loader';
import { getNotificationTimeFormat } from '../../utils/devicelist/deviceListUtil';
import NotificationsActivitySkeleton from './NotificationsActivitySkeleton';
import NotificationsAnnouncementSkeleton from './NotificationsAnnouncementSkeleton';
import { sendGAEvent } from '../../hooks/useGAEvent';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import { LOCALE_CODE } from '../../hooks/useLocale';

const Notifications = (): JSX.Element => {
  const { accountInfo } = useAccountInfo();
  const { t } = useSwitchLocaleLanguage();
  const classes = useStyles();
  dayjs.extend(utc);
  // calling custom hooks
  const {
    callNotificationGetApi,
    callReadNotificationApi,
    notificationDataFromApi,
    changeActivityCategory,
    setIsCallingApi,
    isCallingApi,
    setCurrentPage,
    currentPage,
    endOfPage,
    morePage,
    readflag,
    setReadFlag,
    categoryText,
  } = useNotificationList();

  // notification tooltip handle
  const [isNotificationTooltipShow, setIsNotificationTooltipShow] =
    useState(false);
  const [notificationEl, setnotificationEl] =
    React.useState<null | HTMLElement>(null);
  const isNotificationMenuOpen = Boolean(notificationEl);
  const [badgeContents, setBadgeContents] = useState<string>('');
  const [isToggleActive, setIsToggleActive] = useState(false);
  const [isActivityActive, setIsActivityActive] = useState(true);
  // set announcement data
  const [announcementsData, setAnnouncementsData] = useState<
    NotificationDetails[]
  >(notificationDataFromApi);
  // announcement section state
  const [announcementListHoverId, setAnnouncementListHoverId] =
    useState<string>('');
  const [unreadAnnouncementCount, setUnreadAnnouncementCount] = useState(0);
  const [showAnnouncementDetails, setShowAnnouncementDetails] = useState<
    string[]
  >([]);
  // set activity data
  const [notificationsData, setNotificationsData] = useState<
    NotificationDetails[]
  >(notificationDataFromApi);
  // activity section state
  const [listHoverId, setListHoverId] = useState<string>('');
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [showDetails, setShowDetails] = useState<string[]>([]);

  // start handle card open
  const handleNotificationMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setnotificationEl(event.currentTarget);
    setBadgeContents('');
    // notification tooltip handle
    setIsNotificationTooltipShow(false);
    if (!isNotificationMenuOpen) {
      callNotificationGetApi();
    }
  };

  const handleNotificationMenuClose = () => {
    setnotificationEl(null);
    setIsActivityActive(true);
    setShowDetails([]);
    setShowAnnouncementDetails([]);
    changeActivityCategory(true);
  };
  // end handle card open

  // Toggle and swap
  const onClickToggleBtn = () => {
    setIsToggleActive(!isToggleActive);
    setReadFlag(!readflag);
    setCurrentPage(0);

    // GA tag
    if (isToggleActive) {
      sendGAEvent('press_btn', 'btn_name', 'narrow_down_marks_as_read');
    } else {
      sendGAEvent('press_btn', 'btn_name', 'narrow_down_to_unread');
    }
  };

  const onClickActivityBtn = () => {
    if (!isActivityActive) {
      setIsCallingApi(true);
      setIsActivityActive(true);
      changeActivityCategory(true);
      setCurrentPage(0);

      // GA tag
      sendGAEvent('select_tab', 'txt_name', 'notification_activity_tab');
    }
  };

  const onClickAnnouncementsBtn = () => {
    if (isActivityActive) {
      setIsCallingApi(true);
      setIsActivityActive(false);
      changeActivityCategory(false);
      setCurrentPage(0);

      // GA tag
      sendGAEvent('select_tab', 'txt_name', 'notification_annoucements_tab');
    }
  };
  // start activity section
  useEffect(() => {
    const count = notificationsData.filter(
      (x: NotificationDetails) => x.readFlag === false,
    ).length;
    setUnreadNotificationCount(count);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsData]);

  // start announcement section
  useEffect(() => {
    const count = announcementsData.filter(
      (x: NotificationDetails) => x.readFlag === false,
    ).length;
    setUnreadAnnouncementCount(count);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcementsData]);
  // start text show details & less handle
  const onClickViewDetailsBtn = (id: string, title: string) => {
    setShowDetails([...showDetails, id]);

    // GA tag
    sendGAEvent('press_btn_link', 'link_name', 'view_more');
    sendGAEvent('press_btn_link', 'view_more_value', title);
  };

  const onClickViewLessBtn = (id: string) => {
    const removeData = showDetails.filter((data) => data !== id);
    setShowDetails(removeData);
  };
  // end text show details & less handle

  // handle mouse over
  const handleMouseOver = (id: string) => {
    setListHoverId(id);
  };
  // handle mouse leave
  const handleMouseLeave = () => {
    setListHoverId('');
    setAnnouncementListHoverId('');
  };

  const handleOnClickMarkRead = (id: string, type: string, title: string) => {
    if (type === 'activity') {
      const updatednotificationsData = notificationsData.map((obj) => {
        if (obj.notificationDetailId?.toString() === id) {
          // set the status read
          callReadNotificationApi(parseInt(id, 10));

          return { ...obj, readFlag: true };
        }

        return obj;
      });
      setNotificationsData(updatednotificationsData);
    }
    if (type === 'announcement') {
      const updatedannouncementData = announcementsData.map((obj) => {
        if (obj.notificationDetailId?.toString() === id) {
          // set the status read
          callReadNotificationApi(parseInt(id, 10));

          return { ...obj, readFlag: true };
        }

        return obj;
      });
      setAnnouncementsData(updatedannouncementData);
    }

    // GA tag
    sendGAEvent('press_btn_link', 'link_name', 'mark_as_read');
    sendGAEvent('press_btn_link', 'mark_as_read_value', title);
  };

  // end activity section
  useEffect(() => {
    setNotificationsData(notificationDataFromApi);
    setAnnouncementsData(notificationDataFromApi);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationDataFromApi]);

  // announcement text show details & less handle
  const onClickAnnouncementViewDetailsBtn = (id: string, title: string) => {
    setShowAnnouncementDetails([...showAnnouncementDetails, id]);

    // GA tag
    sendGAEvent('press_btn_link', 'link_name', 'view_more');
    sendGAEvent('press_btn_link', 'view_more_value', title);
  };

  const onClickAnnouncementViewLessBtn = (id: string) => {
    const removeData = showAnnouncementDetails.filter((data) => data !== id);
    setShowAnnouncementDetails(removeData);
  };
  // end text show details & less handle

  // handle mouse over
  const handleAnnouncementMouseOver = (id: string) => {
    setAnnouncementListHoverId(id);
  };

  // end announcement section

  // notification tooltip handle
  const handleNotificationBtnMouseEnter = () => {
    setIsNotificationTooltipShow(true);
  };
  const handleNotificationBtnMouseLeave = () => {
    setIsNotificationTooltipShow(false);
  };
  // scrolling api call
  const observer = useRef<IntersectionObserver | null>(null);
  const lastUserElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          !isCallingApi &&
          !endOfPage &&
          morePage
        ) {
          setCurrentPage(parseInt(currentPage.toString(), 10) + 1);
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setCurrentPage, currentPage, isCallingApi, endOfPage],
  );

  const notificationMenuId = isNotificationMenuOpen
    ? 'simple-popper'
    : undefined;

  // activity view details btn display handle
  const activityDetailsRefs = useRef<(HTMLSpanElement | null)[]>([]);
  const [activityViewDetailsBtn, setActivityViewDetailsBtn] = useState<
    number[]
  >([]);

  useEffect(() => {
    const calculateDimensions = () => {
      const indicesToShow = activityDetailsRefs.current.reduce<number[]>(
        (acc, activityDetailsRef, id) => {
          const container = activityDetailsRef;

          if (container) {
            // const clientHeightNew = container.clientHeight;
            container.style.overflow = 'visible';
            const scrollHeightNew = container.scrollHeight;

            if (scrollHeightNew > 36) {
              acc.push(id);
            }
            container.style.overflow = 'hidden';
          }

          return acc;
        },
        [],
      );

      setActivityViewDetailsBtn(indicesToShow);
    };

    calculateDimensions();
  }, [notificationsData]);

  // announcement view details btn display handle
  const announcementDetailsRefs = useRef<(HTMLSpanElement | null)[]>([]);
  const [announcementViewDetailsBtn, setAnnouncementViewDetailsBtn] = useState<
    number[]
  >([]);

  useEffect(() => {
    const calculateDimensionsAnnouncement = () => {
      const indicesToShowAnnouncement = announcementDetailsRefs.current.reduce<
        number[]
      >((acc, announcementDetailsRef, id) => {
        const container = announcementDetailsRef;

        if (container) {
          // const clientHeightNew = container.clientHeight;
          container.style.overflow = 'visible';
          const scrollHeightNew = container.scrollHeight;

          if (scrollHeightNew > 36) {
            acc.push(id);
          }
          container.style.overflow = 'hidden';
        }

        return acc;
      }, []);

      setAnnouncementViewDetailsBtn(indicesToShowAnnouncement);
    };

    calculateDimensionsAnnouncement();
  }, [announcementsData]);

  // link generate for notification details
  const renderContent = (content: string): React.ReactNode => {
    const linkMatches = Array.from(content.matchAll(linkRegex));

    if (linkMatches.length) {
      const elements: React.ReactNode[] = [];
      let lastIndex = 0;

      linkMatches.forEach((match) => {
        const [fullMatch, linkText, linkUrl] = match;

        const beforeText = content
          .substring(lastIndex, match.index)
          .split('\\n')
          .map((line, index, array) => (
            <React.Fragment key={`${lastIndex}-line-${index}`}>
              {line}
              {index < array.length - 1 && <br />}
            </React.Fragment>
          ));

        elements.push(...beforeText);

        if (linkText.trim() && linkUrl.trim()) {
          elements.push(
            <a
              key={lastIndex}
              href={linkUrl.trim()}
              target="_blank"
              rel="noopener noreferrer"
            >
              {linkText.trim()}
            </a>,
          );
        } else {
          const fullMatchElements = fullMatch
            .split('\\n')
            .map((line, index, array) => (
              <React.Fragment key={`${lastIndex}-fullMatch-${index}`}>
                {line}
                {index < array.length - 1 && <br />}
              </React.Fragment>
            ));
          elements.push(...fullMatchElements);
        }

        lastIndex = Number(match.index) + fullMatch.length;
      });

      if (lastIndex < content.length) {
        const remainingText = content
          .substring(lastIndex)
          .split('\\n')
          .map((line, index, array) => (
            <React.Fragment key={`${lastIndex}-line-${index}`}>
              {line}
              {index < array.length - 1 && <br />}
            </React.Fragment>
          ));

        elements.push(...remainingText);
      }

      return elements;
    }

    return content.split('\\n').map((line, index) => (
      <React.Fragment key={`line-${index}`}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  const renderNotificationMenu = (
    <Popper
      placement="bottom-start"
      id={notificationMenuId}
      open={isNotificationMenuOpen}
      anchorEl={notificationEl}
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [42, 6],
            },
          },
        ],
      }}
      className={classes.notificationMenu}
    >
      <ClickAwayListener onClickAway={handleNotificationMenuClose}>
        <List className={classes.notificationMenuList}>
          <ListItem className={classes.notificationMenuHeader}>
            <Typography className={classes.notificationTitleTextLeft}>
              {t('notification.title')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '7px',
              }}
            >
              <Typography className={classes.notificationTitleTextRight}>
                {t('notification.btn.onlyShowUnread')}
              </Typography>
              <Button
                sx={{ padding: '0px', minWidth: '0px' }}
                onClick={onClickToggleBtn}
                disabled={isCallingApi}
                disableRipple
              >
                <ToggleSwitch switchStatus={isToggleActive} />
              </Button>
            </Box>
          </ListItem>
          <ListItem className={classes.listItemSwap}>
            <Box sx={{ position: 'relative' }}>
              <Button
                type="button"
                aria-label="activity"
                className={classes.listItemSwapBtn}
                sx={{
                  color: isActivityActive ? '#009DE1' : '#939598',
                  '&.Mui-disabled': {
                    color: isActivityActive ? '#009DE1' : '#939598',
                  },
                }}
                onClick={onClickActivityBtn}
                disableRipple
                disabled={isCallingApi}
              >
                <Typography
                  sx={{
                    fontWeight: isActivityActive ? '500' : '400',
                  }}
                  className={classes.listItemSwapBtnText}
                >
                  {t('notification.btn.activity')}
                </Typography>
              </Button>

              {isActivityActive && (
                // eslint-disable-next-line react/self-closing-comp
                <hr
                  className={`${classes.activeBar} active-bar-activity-width`}
                ></hr>
              )}
            </Box>
            <Box sx={{ position: 'relative' }}>
              <Button
                type="button"
                aria-label="activity"
                className={classes.listItemSwapBtn}
                sx={{
                  color: !isActivityActive ? '#009DE1' : '#939598',
                  '&.Mui-disabled': {
                    color: !isActivityActive ? '#009DE1' : '#939598',
                  },
                }}
                onClick={onClickAnnouncementsBtn}
                disabled={isCallingApi}
                disableRipple
              >
                <Typography
                  sx={{
                    fontWeight: !isActivityActive ? '500' : '400',
                  }}
                  className={classes.listItemSwapBtnText}
                >
                  {t('notification.btn.announcements')}
                </Typography>
              </Button>

              {!isActivityActive && (
                // eslint-disable-next-line react/self-closing-comp
                <hr
                  className={`${classes.activeBar} active-bar-announcement-width`}
                ></hr>
              )}
            </Box>
          </ListItem>
          <SimpleBarReact
            style={{ height: 'calc(100vh -  225px)', marginRight: '-14px' }}
          >
            {/* start Activity section */}
            <Box onMouseLeave={handleMouseLeave}>
              {isActivityActive && (
                <>
                  {!(isCallingApi && currentPage === 0) &&
                    (notificationsData.length > 0 ? (
                      <>
                        {notificationsData.map((notification, i) => {
                          const colorIndex = i % 16;
                          const nameWord = notification.initiatorName
                            ?.trim()
                            .charAt(0)
                            .toUpperCase();

                          return (
                            <ListItem
                              alignItems="flex-start"
                              // button
                              key={notification.notificationDetailId}
                              className={classes.activityListItem}
                              sx={{
                                display:
                                  isToggleActive &&
                                  notification.readFlag === true
                                    ? 'none'
                                    : '',
                              }}
                              ref={
                                notificationsData.length === i + 1
                                  ? lastUserElementRef
                                  : null
                              }
                              onMouseOver={() =>
                                handleMouseOver(
                                  notification.notificationDetailId.toString(),
                                )
                              }
                            >
                              {/* eslint-disable-next-line no-nested-ternary */}
                              {notification.initiatorId !== 0 ? (
                                notification.initiatorImageUrl ? (
                                  <img
                                    alt="pic"
                                    src={notification?.initiatorImageUrl}
                                    className={classes.notificationListImg}
                                  />
                                ) : (
                                  <Avatar
                                    className={classes.notificationListAvatar}
                                    sx={{ bgcolor: colorsArray[colorIndex] }}
                                  >
                                    {nameWord}
                                  </Avatar>
                                )
                              ) : (
                                <ActivityIcon
                                  className={classes.notificationIcon}
                                />
                              )}

                              <Box sx={{ ml: '16px' }}>
                                <Typography
                                  className={classes.notificationActivityTitle}
                                  sx={{
                                    color:
                                      notification.readFlag === true
                                        ? '#939598'
                                        : '#313133',
                                  }}
                                >
                                  <b>{notification.bellSubject?.trim()}</b>
                                </Typography>

                                <Typography
                                  ref={(el) => {
                                    activityDetailsRefs.current[
                                      notification.notificationDetailId
                                    ] = el;
                                  }}
                                  id={`notification-content${notification.notificationDetailId}`}
                                  onClick={() => {
                                    const element = document.getElementById(
                                      `notification-content${notification.notificationDetailId}`,
                                    );
                                    const anchorTag =
                                      element?.getElementsByTagName('a');

                                    if (anchorTag && anchorTag.length) {
                                      anchorTag[0].addEventListener(
                                        'click',
                                        () => {
                                          // GA tag
                                          sendGAEvent(
                                            'press_btn_link',
                                            'link_name',
                                            'more_link',
                                          );
                                          sendGAEvent(
                                            'press_btn_link',
                                            'more_link_value',
                                            notification.bellSubject,
                                          );
                                        },
                                      );
                                    }
                                  }}
                                  className={
                                    showDetails.findIndex(
                                      (obj) =>
                                        obj ===
                                        notification.notificationDetailId.toString(),
                                    ) !== -1
                                      ? classes.notificationActivityDetailText
                                      : classes.notificationActivityText
                                  }
                                  sx={{
                                    color:
                                      notification.readFlag === true
                                        ? '#939598'
                                        : '#313133',
                                  }}
                                >
                                  {renderContent(
                                    notification.bellDetail?.trim(),
                                  )}
                                </Typography>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    height:
                                      accountInfo.locale === LOCALE_CODE.FR
                                        ? '18px'
                                        : '',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color:
                                          notification.readFlag === true
                                            ? '#939598'
                                            : '#313133',
                                        fontSize: '10px',
                                      }}
                                    >
                                      {
                                        getNotificationTimeFormat(
                                          notification.notifiedDate,
                                          t,
                                        ).date
                                      }
                                    </Typography>
                                    {activityViewDetailsBtn.includes(
                                      notification.notificationDetailId,
                                    ) && (
                                      <IconButton
                                        type="button"
                                        aria-label="view user"
                                        className={classes.notyIconBtn}
                                        onClick={() =>
                                          showDetails.findIndex(
                                            (obj) =>
                                              obj ===
                                              notification.notificationDetailId.toString(),
                                          ) !== -1
                                            ? onClickViewLessBtn(
                                                notification.notificationDetailId.toString(),
                                              )
                                            : onClickViewDetailsBtn(
                                                notification.notificationDetailId.toString(),
                                                notification.bellSubject,
                                              )
                                        }
                                        disableRipple
                                      >
                                        <Typography
                                          className={classes.notyBtnText}
                                        >
                                          {showDetails.findIndex(
                                            (obj) =>
                                              obj ===
                                              notification.notificationDetailId.toString(),
                                          ) !== -1
                                            ? t('notification.btn.viewLess')
                                            : t('notification.btn.viewDetails')}
                                        </Typography>
                                        {showDetails.findIndex(
                                          (obj) =>
                                            obj ===
                                            notification.notificationDetailId.toString(),
                                        ) !== -1 ? (
                                          <ArrowUp
                                            style={{ marginTop: '3px' }}
                                          />
                                        ) : (
                                          <ArrowDown
                                            style={{ marginTop: '3px' }}
                                          />
                                        )}
                                      </IconButton>
                                    )}
                                  </Box>
                                  {listHoverId ===
                                    notification.notificationDetailId.toString() &&
                                    notification.readFlag === false && (
                                      <IconButton
                                        type="button"
                                        aria-label="view user"
                                        sx={{
                                          maxWidth:
                                            accountInfo.locale ===
                                            LOCALE_CODE.FR
                                              ? '120px'
                                              : 'unset',
                                        }}
                                        className={classes.markAsReadBtn}
                                        onClick={() =>
                                          handleOnClickMarkRead(
                                            notification.notificationDetailId.toString(),
                                            'activity',
                                            notification.bellSubject,
                                          )
                                        }
                                        disableRipple
                                      >
                                        <Typography
                                          className={classes.notyBtnText}
                                        >
                                          {t('notification.btn.markAsRead')}
                                        </Typography>
                                      </IconButton>
                                    )}
                                </Box>
                              </Box>
                            </ListItem>
                          );
                        })}
                        {/* unread activity zero */}
                        {unreadNotificationCount === 0 && isToggleActive && (
                          <Box sx={{ textAlign: 'center', marginTop: '49px' }}>
                            <NoActivityIcon />
                            <Typography
                              className={classes.noNotificationTextPrimary}
                            >
                              {t('notification.message.noActivityFound')}
                            </Typography>
                            <Typography
                              className={classes.noNotificationTextSecondary}
                            >
                              {t('notification.message.noActivitiesAvailable')}
                            </Typography>
                          </Box>
                        )}
                      </>
                    ) : (
                      <Box sx={{ textAlign: 'center', marginTop: '49px' }}>
                        <NoActivityIcon />
                        <Typography
                          className={classes.noNotificationTextPrimary}
                        >
                          {t('notification.message.noActivityFound')}
                        </Typography>
                        <Typography
                          className={classes.noNotificationTextSecondary}
                        >
                          {t('notification.message.noActivitiesAvailable')}
                        </Typography>
                      </Box>
                    ))}
                  {isCallingApi && currentPage === 0 && !badgeContents && (
                    <Box>
                      <NotificationsActivitySkeleton />
                    </Box>
                  )}
                </>
              )}

              {/* End Activity section */}
              {/* Announcement section start */}
              {!isActivityActive && (
                <>
                  {!isCallingApi &&
                    (announcementsData.length > 0 ? (
                      <>
                        {announcementsData.map((announcement) => (
                          <ListItem
                            alignItems="flex-start"
                            // button
                            key={announcement.notificationDetailId.toString()}
                            className={classes.activityListItem}
                            sx={{
                              display:
                                isToggleActive && announcement.readFlag === true
                                  ? 'none'
                                  : '',
                            }}
                            onMouseOver={() =>
                              handleAnnouncementMouseOver(
                                announcement.notificationDetailId.toString(),
                              )
                            }
                          >
                            <Box>
                              <Typography
                                className={classes.notificationAnnouncementText}
                                sx={{
                                  // marginBottom: '4px !important',
                                  color:
                                    announcement.readFlag === true
                                      ? '#939598'
                                      : '#313133',
                                }}
                              >
                                <b>{announcement.bellSubject?.trim()}</b>
                              </Typography>

                              <Typography
                                ref={(el) => {
                                  announcementDetailsRefs.current[
                                    announcement.notificationDetailId
                                  ] = el;
                                }}
                                id={`announcement-content${announcement.notificationDetailId}`}
                                onClick={() => {
                                  const element = document.getElementById(
                                    `announcement-content${announcement.notificationDetailId}`,
                                  );
                                  const anchorTag =
                                    element?.getElementsByTagName('a');

                                  if (anchorTag && anchorTag.length) {
                                    anchorTag[0].addEventListener(
                                      'click',
                                      () => {
                                        // GA tag
                                        sendGAEvent(
                                          'press_btn_link',
                                          'link_name',
                                          'more_link',
                                        );
                                        sendGAEvent(
                                          'press_btn_link',
                                          'more_link_value',
                                          announcement.bellSubject,
                                        );
                                      },
                                    );
                                  }
                                }}
                                // dangerouslySetInnerHTML={{
                                //   __html: announcement.bellDetail,
                                // }}
                                className={
                                  showAnnouncementDetails.findIndex(
                                    (obj) =>
                                      obj ===
                                      announcement.notificationDetailId.toString(),
                                  ) !== -1
                                    ? classes.notificationAnnouncementText
                                    : classes.notificationAnnouncementDoubleLineText
                                }
                                sx={{
                                  color:
                                    announcement.readFlag === true
                                      ? '#939598'
                                      : '#313133',
                                }}
                              >
                                {renderContent(announcement.bellDetail?.trim())}
                              </Typography>

                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  height:
                                    accountInfo.locale === LOCALE_CODE.FR
                                      ? '18px'
                                      : '',
                                }}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color:
                                        announcement.readFlag === true
                                          ? '#939598'
                                          : '#313133',
                                      fontSize: '10px',
                                    }}
                                  >
                                    {dayjs(announcement.notifiedDate).format(
                                      'YYYY/MM/DD HH:mm',
                                    )}
                                  </Typography>
                                  {announcementViewDetailsBtn.includes(
                                    announcement.notificationDetailId,
                                  ) && (
                                    <IconButton
                                      type="button"
                                      aria-label="view user"
                                      className={classes.notyIconBtn}
                                      onClick={() =>
                                        showAnnouncementDetails.findIndex(
                                          (obj) =>
                                            obj ===
                                            announcement.notificationDetailId.toString(),
                                        ) !== -1
                                          ? onClickAnnouncementViewLessBtn(
                                              announcement.notificationDetailId.toString(),
                                            )
                                          : onClickAnnouncementViewDetailsBtn(
                                              announcement.notificationDetailId.toString(),
                                              announcement.bellSubject,
                                            )
                                      }
                                      disableRipple
                                    >
                                      <Typography
                                        className={classes.notyBtnText}
                                      >
                                        {showAnnouncementDetails.findIndex(
                                          (obj) =>
                                            obj ===
                                            announcement.notificationDetailId.toString(),
                                        ) !== -1
                                          ? t('notification.btn.viewLess')
                                          : t('notification.btn.viewDetails')}
                                      </Typography>
                                      {showAnnouncementDetails.findIndex(
                                        (obj) =>
                                          obj ===
                                          announcement.notificationDetailId.toString(),
                                      ) !== -1 ? (
                                        <ArrowUp style={{ marginTop: '3px' }} />
                                      ) : (
                                        <ArrowDown
                                          style={{ marginTop: '3px' }}
                                        />
                                      )}
                                    </IconButton>
                                  )}
                                </Box>
                                {announcementListHoverId ===
                                  announcement.notificationDetailId.toString() &&
                                  announcement.readFlag === false && (
                                    <IconButton
                                      type="button"
                                      aria-label="view user"
                                      sx={{
                                        maxWidth:
                                          accountInfo.locale === LOCALE_CODE.FR
                                            ? '120px'
                                            : 'unset',
                                      }}
                                      className={classes.markAsReadBtn}
                                      onClick={() =>
                                        handleOnClickMarkRead(
                                          announcement.notificationDetailId.toString(),
                                          'announcement',
                                          announcement.bellSubject,
                                        )
                                      }
                                      disableRipple
                                    >
                                      <Typography
                                        className={classes.notyBtnText}
                                      >
                                        {t('notification.btn.markAsRead')}
                                      </Typography>
                                    </IconButton>
                                  )}
                              </Box>
                            </Box>
                          </ListItem>
                        ))}
                        {/* unread activity zero */}
                        {unreadAnnouncementCount === 0 && isToggleActive && (
                          <Box sx={{ textAlign: 'center', marginTop: '49px' }}>
                            <NoAnnouncementIcon />
                            <Typography
                              className={classes.noNotificationTextPrimary}
                            >
                              {t('notification.message.noAnnouncement')}
                            </Typography>
                            <Typography
                              className={classes.noNotificationTextSecondary}
                            >
                              {t(
                                'notification.message.noAnnouncementsAvailable',
                              )}
                            </Typography>
                          </Box>
                        )}
                      </>
                    ) : (
                      <Box sx={{ textAlign: 'center', marginTop: '49px' }}>
                        <NoAnnouncementIcon />
                        <Typography
                          className={classes.noNotificationTextPrimary}
                        >
                          {t('notification.message.noAnnouncement')}
                        </Typography>
                        <Typography
                          className={classes.noNotificationTextSecondary}
                        >
                          {t('notification.message.noAnnouncementsAvailable')}
                        </Typography>
                      </Box>
                    ))}
                  {isCallingApi && currentPage === 0 && !badgeContents && (
                    <Box>
                      <NotificationsAnnouncementSkeleton />
                    </Box>
                  )}
                </>
              )}
              {isCallingApi && currentPage > 0 && (
                <Box className={classes.notificationLoaderBoxBottom}>
                  <Loader colorCode="#b69696" />
                </Box>
              )}
              {isCallingApi && badgeContents && (
                <Box className={classes.notificationLoaderBox}>
                  <Loader colorCode="#b69696" />
                </Box>
              )}
            </Box>
          </SimpleBarReact>
        </List>
      </ClickAwayListener>
    </Popper>
  );

  useEffect(() => {
    // let unsubscribe: any;
    if (accountInfo.notificationApiCallDone) {
      // const counterRef = collection(fireBaseDB, 'counter_value');
      // const queryValue = query(
      //   counterRef,
      //   where('id', '==', accountInfo.userId),
      // );
      // const docRef = doc(
      //   fireBaseDB,
      //   'notification',
      //   accountInfo.userId.toString(),
      // );
      // onSnapshot(docRef, (snapshot) => {
      //   console.log('Snapshot', snapshot?.data());
      //   const data: { count: string } = snapshot?.data() as { count: string };
      //   if (data?.count?.toString() === '0') {
      //     setBadgeContents('');
      //   } else {
      //     setBadgeContents(data?.count);
      //   }
      // });
    }
    const docRef = doc(
      fireBaseDB,
      'js_rh_stg_pt_console_notification',
      accountInfo.userId.toString(),
    );
    onSnapshot(docRef, (snapshot) => {
      const data: { count: string } = snapshot?.data() as { count: string };
      if (data?.count?.toString() === '0') {
        setBadgeContents('');
      } else {
        setBadgeContents(data?.count);
      }
    });
  }, [accountInfo.notificationApiCallDone, accountInfo.userId]);

  useEffect(() => {
    if (isNotificationMenuOpen) {
      callNotificationGetApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, categoryText, readflag, badgeContents]);
  useEffect(() => {
    if (accountInfo.userRoleChangeApiCall) {
      setnotificationEl(null);
      setIsActivityActive(true);
      setShowDetails([]);
    }
  }, [accountInfo.userRoleChangeApiCall]);

  return (
    <>
      <Box
        onMouseEnter={handleNotificationBtnMouseEnter}
        onMouseLeave={handleNotificationBtnMouseLeave}
      >
        <Tooltip
          open={isNotificationTooltipShow}
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -8],
                },
              },
            ],
          }}
          title={t('profileMenu.notificationTooltip')}
          placement="bottom"
          sx={{ marginTop: 0 }}
          arrow
          componentsProps={{
            tooltip: {
              className: classes.iconTooltipStyle,
            },
          }}
        >
          <IconButton
            size="large"
            aria-label="show 1 new notifications"
            aria-controls={notificationMenuId}
            aria-describedby={notificationMenuId}
            color="inherit"
            aria-haspopup="true"
            onClick={handleNotificationMenuOpen}
            className={classes.headerIcon}
          >
            {badgeContents && !isNotificationMenuOpen ? (
              <Badge
                badgeContent={badgeContents}
                className={classes.notificationBadge}
              >
                <NotificationImg />
              </Badge>
            ) : (
              <NotificationImg />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      {renderNotificationMenu}
    </>
  );
};

export default Notifications;
