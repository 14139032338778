import { useEffect, useRef } from 'react';
import SimpleBarReact from 'simplebar-react';
import TableTemplateContainer from './TableTemplateContainer';
import useGroupDeviceList from '../../hooks/useGroupDeviceList';
import { TABLE_ROW_NUMBER_PER_PAGE } from '../../constants/constants';
import styles from './GroupDeviceListTable.module.scss';

type Props = {
  searchText: string;
  openManageGroupDialog: boolean;
  noDataErrorFromDeviceTable: (val: boolean) => void;
  errorFromDeviceTable: (val: boolean) => void;
};

const GroupDeviceListTableTemplate = ({
  searchText,
  openManageGroupDialog,
  noDataErrorFromDeviceTable,
  errorFromDeviceTable,
}: Props) => {
  const {
    currentPageDataRows,
    headerColumns,
    sortValue,
    paginationValue,
    errorType,
    isCallingApi,
  } = useGroupDeviceList({ searchText, openManageGroupDialog });

  // declar Ref for the table body simple scroll bar
  const simpleBarRef: React.LegacyRef<SimpleBarReact> = useRef(null);
  if (paginationValue.totalData === 0) {
    noDataErrorFromDeviceTable(true);
  } else {
    noDataErrorFromDeviceTable(false);
  }
  if (errorType) {
    errorFromDeviceTable(true);
  } else {
    errorFromDeviceTable(false);
  }

  useEffect(() => {
    const tableBody = document.getElementsByClassName(
      'table-body-simplebar-detail',
    )[0];
    if (tableBody) {
      const scrollContainerClass = tableBody.querySelector(
        '.simplebar-content-wrapper',
      );
      if (scrollContainerClass) {
        scrollContainerClass.scrollTop = 0;
      }
    }
  }, [currentPageDataRows]);

  return (
    <div className={styles['device-table-container']}>
      {/* {!errorType && !(paginationValue.totalData === 0 && !isCallingApi) && ( */}
      {!errorType && paginationValue.totalData > 0 && (
        <TableTemplateContainer
          simpleBarRef={simpleBarRef}
          headerColumns={headerColumns}
          dataRows={currentPageDataRows}
          totalDataRowsLabel="Device"
          totalDataRows={paginationValue.totalData}
          clickSortButton={sortValue.onClickSortButton}
          sortConfig={sortValue.sortConfig}
          currentPage={paginationValue.currentPage}
          totalPages={paginationValue.totalPages}
          clickPrevPageButton={paginationValue.onClickPrevPage}
          clickNextPageButton={paginationValue.onClickNextPage}
          clickFirstPageButton={paginationValue.onClickFirstPage}
          clickLastPageButton={paginationValue.onClickLastPage}
          rowNumberPerPages={TABLE_ROW_NUMBER_PER_PAGE}
          currentRowNumberPerPage={paginationValue.currentRowNumberPerPage}
          changeRowNumber={paginationValue.onChangeRowNumber}
          containFirstAndLastButton
          pageDataIndexFrom={paginationValue.pageDataIndexFrom}
          pageDataIndexTo={paginationValue.pageDataIndexTo}
          isCallingApi={isCallingApi}
          tableSrcPage="GroupDevice"
        />
      )}
    </div>
  );
};

export default GroupDeviceListTableTemplate;
